<template>
    <div class="shadow-lg rounded-2xl">
  
      <da-header-title :title="'Actualizar Personal'" />
        <div class="bg-white h-auto py-5 px-5 rounded-bl-lg rounded-br-lg">
          <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5" v-if="user && user.name">
             
              <da-text-field-primary 
                  :icon="'mdi-account'"
                  :label="'Nombre:'"
                  :value="$v.user.name"
                  :error="$v.user.name.$invalid && $v.user.name.$anyDirty" 
                  v-model="$v.user.name.$model"
                  text
              />
              <da-text-field-primary 
                  :icon="'mdi-phone'"
                  :label="'Teléfono:'"
                  :value="$v.user.phone" 
                  v-model="$v.user.phone.$model"
                  :error="$v.user.phone.$invalid && $v.user.phone.$anyDirty"
                  number
                  :rules="[ ($v.user.phone.$anyDirty ? $v.user.phone.phonePattern : true) || 'Máximo 10 caracteres']"
              />
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
              <da-text-field-primary 
                  :icon="'mdi-at'"
                  :label="'Correo:'"
                  :value="$v.user.email"
                  :error="$v.user.email.$invalid && $v.user.email.$anyDirty" 
                  v-model="$v.user.email.$model"
                  email
                  :rules="[ ($v.user.email.$anyDirty ? $v.user.email.emailPattern : true) || 'Ingresa un correo valido']"
              />
              <v-autocomplete
              :color="'#E7AC18'"
              :items="roles"
              label="Puesto:"
              v-model="$v.user.id_rol.$model"
              item-text="desc_rol"
              item-value="id_rol"
              :error="$v.user.id_rol.$invalid && $v.user.id_rol.$anyDirty"
              >
              <template slot="item" slot-scope="data">
                  <span class="text-primary-900">{{ data.item.desc_rol ? data.item.desc_rol : '' }}</span>
              </template>
              </v-autocomplete>
          </div>
          <div class="flex justify-start">
              <div class="flex gap-2 justify-start align-middle">
                  <da-button-primary @click.native="onSave"  :label="'GUARDAR'" r2xl swpr class="py-1 px-5 font-light"></da-button-primary>
                  <da-button-secondary @click.native="$emit('close')" :label="'CANCELAR'" rxl swsec class="py-1 px-5 font-light"></da-button-secondary>
              </div>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  import DAUserModel from '../../models/user/DAUserModel';
  import { validationMixin } from 'vuelidate';
  import { required } from 'vuelidate/lib/validators';
  import moduleTypes from '../../store/modules/common/module-types';
  import fnTypes from '../../store/modules/common/fn-types';
  import { emailPattern, phonePattern } from '../../utils/common/regex'
  
  
  export default {
      mixins: [validationMixin],
      props: {
        id: {
            type: String,
            default: ""
        }
      },
      data: function() {
          return {
              user: new DAUserModel(),
              showPWD: false
          }
      },
      validations: {
          user: {
              email: {
                   required,
                   emailPattern
              },
              phone: {
                  required,
                  phonePattern
              },
              id_rol: {
                   required
              },
              name: {
                   required
              }
           }
      },
      created() {
          this.init()
      },
      computed: {
          ...mapGetters(moduleTypes.ROLE, {
              roles: fnTypes.getRoles,
          }),
          ...mapGetters(moduleTypes.USER, {
            hasErrorInService: fnTypes.hasErrorInService,
            userModel: fnTypes.getUser,
          }),
      },
      methods: {
          ...mapActions(moduleTypes.ROLE, [
              fnTypes.getRoles
          ]),
          ...mapActions(moduleTypes.USER, [
              fnTypes.update, fnTypes.getUser
          ]),
          init: async function() {
              this.$v.$reset()
              this.user = new DAUserModel()
              await this.getUserById(this.id)
              await this.getRoles()
          },
          onSave: async function() {
              this.$v.$touch();
              if(!this.$v.$anyError) {
                  await this.update({data: this.user, id: this.id})
                  if(!this.hasErrorInService.error) {
                      this.$emit('success', true)
                  }
              }
          },
          getUserById: async function(id) {
            await this.getUser(id)
            if(this.userModel) {
                this.user = this.userModel
            }
        },
      }
  }
  </script>
  
  <style>
  
  
  </style>