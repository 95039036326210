<template>
    <div class="ml-10 mr-10 mt-5 mb-5">

        <da-button-primary v-if="updateSignature == false"  @click.native="onModifySignature()" :label="'Modificar'" rxl swpr class="py-1 px-3 mb-5 font-light"></da-button-primary>
        <da-button-primary v-if="updateSignature && !isUpdateModel" @click.native="onSaveConfig()" :label="'GUARDAR'" rxl swpr class="py-1 px-3 mr-2 mb-5 font-light"></da-button-primary>
        <da-button-primary v-if="updateSignature && isUpdateModel"  @click.native="onEditConfig()" :label="'GUARDAR'" rxl swpr class="py-1 px-3 mr-2 mb-5 font-light"></da-button-primary>
        <da-button-secondary v-if="updateSignature ==true"  @click.native="onCancelModifySignature()" :label="'Cancelar'" rxl swpr class="py-1 px-3 mb-5 font-light "></da-button-secondary>

        <da-header-title :title="'Declaración'" class=" font-medium tracking-wider mt-5 mb-5" />
            <da-text-area-primary 
                :label="'Texto DECLARO'"           
                v-model ="declaracion"
                :value ="declaracion"
                :readonly="isModify"
            > 
            </da-text-area-primary>    

            <da-text-area-primary 
                :label="'Texto CONSIENTO'"           
                class="mt-5"
                v-model="consentimiento"
                :value="consentimiento"
            > 
            </da-text-area-primary>    


            <da-header-title :title="'Datos del Doctor'" class=" font-medium tracking-wider mt-5" />


            <div class="mt-5">

            <da-text-field-primary 
            class="mt-2"
                :label="'Nombre Doctor:'"
                text
                v-model="nombredr"
                :value="nombredr"
            />

            

            <div class="mt-5">
                <label class="text-base"  for="">Firma:</label>
                <da-signature-pad v-if="updateSignature == true && url_signature == ''" v-on:signed="onSigned"></da-signature-pad>
                <img v-if="updateSignature == false  && url_signature != ''" :src="url_signature" alt="Firma" srcset="">
            </div>
            

        </div>

        

        </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VuexMixin from '../../mixins/VuexMixin';
import moduleTypes from '../../store/modules/common/module-types';
import fnTypes from '../../store/modules/common/fn-types';

    export default {
        mixins:[VuexMixin],
        data: function() {
            return {
                isNew: true,
                isDelete: false,
                isEdit: false,
                currentID: '',
                consentimiento: '',
                declaracion: '',
                nombredr: '',
                id_signature_consent: '',
                key_signature: '',
                newSignature: '',
                url_signature:'',
                idsignatunature:'',
                updateSignature: false,
                isModify: true
            }
        },
        created() {
            this.init()
        },
        computed: {
            ...mapGetters(moduleTypes.CONFIGURATION, {
                saveConfigurations:     fnTypes.saveConfiguration,
                getConfigurations:      fnTypes.getConfiguration,
                editConfigurations:     fnTypes.editConfiguration,
                hasErrorConfiguration:  fnTypes.hasErrorInService
            }),

            isUpdateModel: function() {
                return !this.consentimiento == '' && !this.declaracion == '' && !this.nombredr == '' && !this.url_signature == '' ? false : true;
            }
        },
        methods: {

        ...mapActions(moduleTypes.CONFIGURATION, [
            fnTypes.saveConfiguration,
            fnTypes.editConfiguration,
            fnTypes.getConfiguration
        ]),

        init: async function() {
            await this.onGetConfiguration()
        },
        onSaveConfig: async function(){

                if (this.consentimiento == '' || this.declaracion == '' || this.nombredr == ''){

                    this.toastFunction('error','Campos Vacios.')
                    return 
                }

                if (this.newSignature == '' || this.newSignature == undefined){

                    this.toastFunction('error','No se ha "Aplicado" la firma.')
                    return 
                }

                let data = {
                    'consent':  this.consentimiento,
                    'state':    this.declaracion,
                    'name':     this.nombredr,
                    'files':{
                        'file': this.newSignature
                    }
                };

                await this.saveConfiguration(data)
                window.location.reload();
                },

                onEditConfig: async function(){

                if (this.consentimiento == '' || this.declaracion == '' || this.nombredr == ''){

                    this.toastFunction('error','Campos Vacios.')
                    return 
                }

                if (this.newSignature == '' || this.newSignature == undefined){

                    this.toastFunction('error','No se ha "Aplicado" la firma.')
                    return 
                }

                let data = {
                    'id_signature_consent': this.id_signature_consent,
                    'key_signature': this.key_signature ,
                    'url_signature': '',
                    'consent':  this.consentimiento,
                    'state':    this.declaracion,
                    'name':     this.nombredr,
                    'files':{
                        'file': this.newSignature
                    }
                };

                await this.editConfiguration(data)
                    window.location.reload();

                },

            onGetConfiguration: async function(){
                await this.getConfiguration()
        },

        onSigned: function(data) {
            this.newSignature = data;
        },

        onModifySignature: function(){
            this.idsignatunature = this.url_signature
            this.url_signature = ''
            this.updateSignature = true
            this.isModify = false
        },

        onCancelModifySignature: function(){
            this.url_signature = this.idsignatunature
            this.updateSignature = false
            this.isModify = true
        },

        toastFunction: function(type,text){
            const Toast = this.$swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            })

                Toast.fire({
                icon: type,
                title: text
            })
        }
      },
      watch: {
        getConfigurations: function() {
            if(this.getConfigurations) {
                this.consentimiento         = this.getConfigurations.consent
                this.declaracion            = this.getConfigurations.state
                this.nombredr               = this.getConfigurations.name
                this.key_signature          = this.getConfigurations.key_signature
                this.id_signature_consent   = this.getConfigurations.id_signature_consent
                this.url_signature          = this.getConfigurations.url_signature
            }
        }
      }

    }
</script>

<style lang="scss" scoped>

</style>