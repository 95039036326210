<template>
    <div class="sm:px-5 md:px-5 lg:mx-20% sm:md:py-2 md:py-5">
            <v-tabs 
            v-model="tab" 
            class="rounded-tl-lg rounded-tr-lg" 
            grow
            active-class="active-tab"
            color="#BC890A" 
            background-color="#FBEAC6" 
           >
                <v-tab value="one">Personal</v-tab>
                <!-- <v-tab value="two">Almacen</v-tab> -->
                <v-tab value="two">configuración</v-tab>

            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item >
                    <da-user-index v-if="tab == 0"></da-user-index>
                </v-tab-item>
<!-- 
                <v-tab-item >
                    <da-store-index v-if="tab == 1"></da-store-index>
                </v-tab-item> -->

                <v-tab-item >
                    <da-config-index v-if="tab == 1"></da-config-index>
                </v-tab-item>

        </v-tabs-items>

    </div>
</template>

<script>
// import AlmacenIndex from '../store/index.vue';
import UserIndex from '../User/DAUser.vue';
import ConfigIndex from '../configuration/index.vue';

    export default {
        components: {
            // 'da-store-index': AlmacenIndex,
            'da-user-index': UserIndex,
            'da-config-index': ConfigIndex,
        },
        data: function() {
            return {
                tab: 0,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>